.carousel {
    display: flex;
    align-items: center;
  }
  
  .carousel-item {
    margin: 20px 0;
    transition: transform 0.5s ease-in-out;
  }
  
  .dots {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .dot {
    height: 10px;
    width: 10px;
    margin: 0 5px;
    background-color: lightgray;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
  }
  
  .dot.active {
    background-color: gray;
  }
  