
.custom-bg-ilus {
  background: linear-gradient(132.98deg, rgba(47, 129, 174, 0.7) 45.11%, rgba(84, 49, 193, 0.7) 127.54%);
  filter: blur(155.322px);
}


.one {
  transform: translatey(0px);
	animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-40px);
  }
  100% {
    transform: translatey(0px);
  }
}


.animated-border {
  padding: 12px;
  border: 3px solid #0000;
  border-radius: 12px;
  background: 
  linear-gradient(#131219, #131219) padding-box, /* Inner content background */
  linear-gradient(
    var(--angle), /* Animation angle */
    #9DE8EE 25.05%, 
    #FA7C0B 51.71%, 
    #9F8CED 79.45% /* Your custom gradient */
  ) border-box; /* Outer border background */
  animation: 4s rotate linear infinite;
}

@keyframes rotate {
  to {
    --angle: 360deg;
  }
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}


.primary-color{
  background: linear-gradient(217.81deg, #9DE8EE 25.05%, #9DE8EE 25.05%, #FA7C0B 51.71%, #9F8CED 79.45%);
}


.card-bg{
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./public/images/card_bg.jpg');
  background-size:cover;
  background-blend-mode: darken;
  background-repeat: no-repeat;
}



.about-ul {
  list-style-type: none; /* Remove default bullet points */
  padding: 0; /* Remove padding */
}

.about-li {
  position: relative; /* Required for absolute positioning of the star */
  padding-left: 20px; /* Space for the star */
}

.about-li::before {
  content: '*'; /* Use star as bullet */
  position: absolute; /* Position it relative to the list item */
  left: 0; /* Align to the left */
  color: #FFD700; /* Change the color of the star (gold in this case) */
  font-weight: bold; /* Make the star bold */
}



.internship_card_border {
  border: 3px solid #0000;
  border-radius: 12px;
  background: linear-gradient(#131219, #131219) padding-box, linear-gradient(
        var(--angle),
        #070707,
        #687aff
      ) border-box;
  animation: 2s rotate linear infinite;
}

@keyframes rotate {
  to {
    --angle: 360deg;
  }
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}




.contact-card-button{
        background: linear-gradient(97.56deg, #2A6BF2 -44.89%, #2D3794 95.32%);
}


.text-blue-color{
    color: linear-gradient(97.56deg, #2A6BF2 -44.89%, #2D3794 95.32%);
}


@keyframes loader-spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
@keyframes loader-bounce {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-15px); }
}


.container {
  max-width: 800px;
  height:1040px;
  margin: 0 auto;
  padding: 0;
  
}
.letter-header {
  text-align: center;
  margin-bottom: 20px;
}
.letter-body {
  text-align: justify;
  margin-bottom: 20px;
  padding: 35px;
  font-size: large;
}
.contact-info {
  margin-top: 20px;
  text-align: justify;
}
.contact-info p {
  margin: 5px 0;
}